import { $GET } from '../plugins/Fetch.js';
export const Calendar = () => {
  const url = `/events/${window.eventID}`;
  return {
    loading: true,
    eventData: {},
    acts: {
      setup: {},
      breakdown: {},
      rehearsal: {},
      dropoff: {},
      appetizers: {},
      dinner: {},
      cake: {},
      toast: {},
      second_room: {},
    },

    init() {
      $GET(url)
        .then((resp) => {
          window.calendar = resp;
          for (let key in resp.event) {
            this.eventData[key] = resp.event[key];
          }

          for (let key in resp.acts) {
            this.acts[key] = resp.acts[key];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  };
};
