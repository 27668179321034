'use strict';

import { $PATCH } from '../../plugins/Fetch';

window.StaffConfirmStatus = () => {
  let data = {
    saving: false,
    shouldCancel: false,
    toast: {
      success: '',
      error: null,
    },
    statuses: [],
  };
  return {
    ...data,
    init() {
      let config = window.app_data;
      for (let k in config) {
        this[k] = config[k];
      }
    },
    setStatus(e) {
      let record = this.shift || this.shift_able;
      record.status = e.target.dataset.status;
    },
    reset() {
      this.toast.success = this.toast.error = null;
      this.shouldCancel = false;
    },
    confirm() {
      this.shift.status = 'confirmed';
      this.save();
    },
    cancel() {
      this.shift.status = 'cancelled_by_staff';
      this.save();
    },
    save() {
      if (this.saving) return;
      this.saving = !this.saving;
      this.reset();
      let data = this.shift
        ? {
            id: this.shift.id,
            shift: {
              id: this.shift.id,
              status: this.shift.status,
            },
          }
        : { shift_able: this.shift_able };
      this.request(this.routes.rsvp, data)
        .then((resp) => {
          this.toast.success = 'Saved!';
        })
        .catch((err) => {
          this.toast.error = 'Oops there was an error. Please contact us';
        })
        .finally(() => {
          this.saving = !this.saving;
          setTimeout(() => {
            this.reset();
          }, 3000);
        });
    },
    request(url, data) {
      let body = {
        event_id: this.eventId,
        token: this.token,
        ...data,
      };

      return $PATCH(url, body)
        .then((resp) => resp)
        .catch((err) => {
          debugger;
        });
    },
  };
};
